import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

// Source: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/migration-guide.md#acquiring-an-access-token
export function useAccessToken() {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        if (accounts.length > 0) {
            const request = {
                scopes: ["48e87842-befe-4619-9a89-fd5cd4df41e0/.default"],
                account: accounts[0]
            };
            
            instance.acquireTokenSilent(request).then(response => {
                setAccessToken(response.accessToken);
            }).catch(error => {
                // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup(request).then(response => {
                        setAccessToken(response.accessToken);
                    });
                }
            });
        }
    }, [instance, accounts]); // Only re-run the effect if instance or accounts change

    // console.log("aquired token", accessToken)

    return accessToken;
}

import React, { useState } from "react";
import MainForm from "./MainForm";
import { useAccessToken } from "../hooks/useAccessToken";

function MyComponent() {
  //const [profile, setProfile] = useState("");
  //const [linkedinProfile, setLinkedinProfile] = useState("");
  const [editableSummary, setEditableSummary] = useState("");
  const [linkedinMessage, setLinkedinMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const accessToken = useAccessToken();

  const handleSubmit = async (behavior, profile, context) => {
    setIsLoading(true);

    // console.log('obtained token', accessToken)
    // TODO: doublecheck accessToken not null
    // console.log('Submitting', { behavior, profile });

    // const response = await fetch(
    //   "http://localhost:8000/summarize?profile_raw=testestest",
    //   {
    //     method: "POST", // Specify the method
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`, // Include the Authorization header
    //     },
    //     // If you have a body payload, include it here as a JSON string
    //     // body: JSON.stringify({ your: "dataPayload" })
    //   }
    // );
    // console.log("test", await response.json());

    var baseUrl = "";
    if (process.env.NODE_ENV === "development") {
      baseUrl = "http://localhost:8000";
    } else {
      baseUrl = "https://dev.api.blobgpt.edgez.live";
    }

    try {
      const summaryUrl = `${baseUrl}/summarize?profile_raw=${encodeURIComponent(
        profile
      )}`;

      const summaryResponse = await fetch(summaryUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const summaryResponseData = await summaryResponse.json();

      const newEditableSummary = summaryResponseData.profile_summary.content;
      setEditableSummary(newEditableSummary); // Set state for future renders

      const generateUrl = `${baseUrl}/generate?profile_summary=${encodeURIComponent(
        newEditableSummary
      )}&context=${encodeURIComponent(context)}`;

      const messageResponse = await fetch(generateUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const messageResponseData = await messageResponse.json();
      const newMessage = messageResponseData.message.content;

      setLinkedinMessage(newMessage);
      const generatedResult = {
        editableSummary: newEditableSummary,
        linkedinMessage: newMessage,
      };
      setResult(generatedResult);
      console.log("result", generatedResult);
    } catch (error) {
      console.error("Error:", error);
      // Optionally handle the error here
    } finally {
      setIsLoading(false); // Turn off loading indicator after operations are complete
    }
  };

  return (
    <MainForm
      onSubmit={handleSubmit}
      summary={editableSummary}
      linkedinMessage={linkedinMessage}
      isLoading={isLoading}
    />
  );
}

export default MyComponent;

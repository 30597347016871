import React, { useState, useEffect } from "react";

function ButtonClipboard({ textToCopy }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        // Optionally, implement a notification to the user
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleButtonClick = () => {
    copyToClipboard(textToCopy);
    setIsCopied(true);
  };

  useEffect(() => {
    let timer;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000); // Hide the text after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <>
      <button
        onClick={handleButtonClick}
        className="btn btn-clipboard btn-sm mx-1"
      >
        <i className="bi bi-clipboard"></i>
      </button>
      {isCopied && <small className="text-muted">copied to clipboard</small>}
    </>
  );
}

export default ButtonClipboard;

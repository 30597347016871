import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const Login = () => {
  const { instance } = useMsal();
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div className="container">
      <div style={{ height: "50px" }}></div>
      <div style={{ textAlign: "center", marginBottom: "120px" }}>
        <h1>BlobGPT Login</h1>
        <div style={{ height: "50px" }}></div>
        <div onClick={handleLoginRedirect} style={{ cursor: "pointer" }}>
          <img src="/img/ms_signin_light.svg" alt="Sign in with Microsoft" />
        </div>
      </div>
      <div style={{ textAlign: "right" }}>
        <img src="/img/edgez-badge.svg" alt="Edge Badge" width="200" />
      </div>
    </div>
  );
};

export default Login;

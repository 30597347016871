import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import ModelToggle from "./ModelToggle";

export const NavigationBar = () => {
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light">
        <AuthenticatedTemplate>
          <div className="container-fluid">
            <div className="d-flex justify-content-start w-50">
              <div className="navbar-text mx-3">
                <ModelToggle />
              </div>
            </div>
            <div className="d-flex justify-content-end w-120">
              <span className="navbar-text mx-3">
                Welcome, {activeAccount ? activeAccount.name : "Unknown"}
              </span>
              <button
                className="btn btn-outline-primary"
                onClick={handleLogoutRedirect}
              >
                Logout
              </button>
            </div>
          </div>
        </AuthenticatedTemplate>
      </nav>
    </>
  );
};

import { NavigationBar } from "./NavigationBar.js";

export const PageLayout = (props) => {
  return (
    <>
      <NavigationBar />
      {props.children}
    </>
  );
};

import React, { useState } from "react";
import ButtonSummarize from "./ButtonSummarize";
import ButtonClipboard from "./ButtonClipboard";

function MainForm({ onSubmit, summary, linkedinMessage, isLoading }) {
  const [selectedOption, setSelectedOption] = useState("option1");
  const [selectedOptionLanguage, setSelectedOptionLanguage] =
    useState("optionLanguage1");
  const [profile, setProfile] = useState("");
  const [context, setContext] = useState("");
  const [profileSummary, setProfileSummary] = useState("");
  const [bmessage, setMessage] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };

  const handleOptionLanguageChange = (event) => {
    setSelectedOptionLanguage(event.target.id);
  };

  const handleProfileChange = (event) => {
    setProfile(event.target.value);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleProfileSummaryChange = (event) => {
    setProfileSummary(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFormSubmission = () => {
    onSubmit(selectedOption, profile, context);

    // Optionally, after submission, you can update the result
    //onResultChange({ editableSummary : profileSummary, linkedinMessage : blobMessage });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col text-start">
          <label className="form-label">Select Behavior</label>
          <div id="behavior">
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={selectedOption === "option1"}
              onChange={handleOptionChange}
            />
            <label className="btn btn-outline-primary me-3" htmlFor="option1">
              Casual
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option2"
              autoComplete="off"
              checked={selectedOption === "option2"}
              onChange={handleOptionChange}
              disabled
            />
            <label className="btn btn-outline-primary me-3" htmlFor="option2">
              Business
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option3"
              autoComplete="off"
              checked={selectedOption === "option3"}
              onChange={handleOptionChange}
              disabled
            />
            <label className="btn btn-outline-primary" htmlFor="option3">
              Friendly
            </label>
          </div>
        </div>
        <div className="col text-end">
          <label className="form-label">Select Language</label>
          <div id="language">
            <input
              type="radio"
              className="btn-check"
              name="optionsLanguage"
              id="optionLanguage1"
              autoComplete="off"
              checked={selectedOptionLanguage === "optionLanguage1"}
              onChange={handleOptionLanguageChange}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="optionLanguage1"
            >
              German
            </label>

            <input
              type="radio"
              className="btn-check"
              name="optionsLanguage"
              id="optionLanguage2"
              autoComplete="off"
              checked={selectedOptionLanguage === "optionLanguage2"}
              onChange={handleOptionLanguageChange}
              disabled
            />
            <label
              className="btn btn-outline-primary ms-3"
              htmlFor="optionLanguage2"
            >
              English
            </label>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="linkedinProfile" className="form-label">
          LinkedIn Profile <small className="text-muted">required</small>
        </label>
        <textarea
          className="form-control"
          id="linkedinProfile"
          style={{ height: "200px" }}
          placeholder="Your LinkedIn profile text belongs here..."
          value={profile}
          onChange={handleProfileChange}
        ></textarea>
        <div id="linkedinHelpBlock" className="form-text">
          Kindly highlight all the content using Ctrl + A (or ⌘ + A on a Mac),
          then copy and paste the LinkedIn profile from your browser into this
          box.
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="additionalContext" className="form-label">
          Additional Context <small className="text-muted">optional</small>
        </label>
        <textarea
          className="form-control"
          id="additionalContext"
          // style={{ height: "200px" }}
          placeholder="Provide additonal context for the model to consider..."
          value={context}
          onChange={handleContextChange}
        ></textarea>
        <div id="additionalContextHelpBlock" className="form-text">
          Feel free to add any extra details, such as your personal connection
          with this individual or the duration of your association with
          Netlight. This information will guide the AI's output. Use your
          imagination and provide as much context as you're comfortable with.
        </div>
      </div>

      <div className="mb-3">
        <div className="d-grid gap-2">
          <button
            className="btn btn-primary width-100"
            onClick={handleFormSubmission}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Generating</span>{" "}
                {/* For screen readers */}
              </>
            ) : (
              "Generate"
            )}
          </button>
        </div>
      </div>

      {summary && (
        <div className="mb-3">
          <label htmlFor="linkedinProfileSummary" className="form-label">
            LinkedIn Profile Summary <small className="text-muted">read only</small>
          </label>
          <textarea
            className="form-control"
            id="linkedinProfileSummary"
            style={{ height: "200px" }}
            placeholder="Your LinkedIn profile summary will appear here..."
            value={summary}
            onChange={handleProfileSummaryChange}
          ></textarea>
        </div>
      )}

      {linkedinMessage && (
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message <small className="text-muted">read only</small>
          </label>
          <ButtonClipboard textToCopy={linkedinMessage} className="mx-3" />
          <textarea
            className="form-control"
            id="message"
            style={{ height: "200px" }}
            placeholder="Your LinkedIn message will appear here..."
            value={linkedinMessage}
            onChange={handleMessageChange}
          ></textarea>
        </div>
      )}

      {/* 
      <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          Email address
        </label>
        <input
          type="email"
          className="form-control"
          id="exampleFormControlInput1"
          placeholder="name@example.com"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">
          Example textarea
        </label>
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
      </div> */}
    </>
  );
}

export default MainForm;

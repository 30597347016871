import React from "react";

function ModelToggle() {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        disabled
      />
      <label className="form-check-label" for="flexSwitchCheckDefault">
        OpenAI GPT-3.5 (fast)
      </label>
    </div>
  );
}

export default ModelToggle;
